.steps{
    &__inner{
        padding: 75px 0 90px;
        position: relative;
        background-color: #F6F6F6;
    }
    &__title{
        padding: 0 145px;
        margin-bottom: 25px;
    }
    &__subtitle{
        text-align: center;
        margin-bottom: 62px;
    }
    &__content{
        position: relative;
        background: #FFFFFF;
        box-shadow: 0px 4px 20px rgba(210, 210, 210, 0.5);
        border-radius: 5px;
        display: flex;
        flex-wrap: nowrap;
        border-radius: 5px;
        z-index: 0;
    }
    .print{
        right: 19.55%;
        top: 18.5%;
    }
    &__list{
        list-style: none;
        padding-left: 0;
        width: 189px;
        margin: 0;
    }
    &__item{
        width: 100%;
        text-align: center;
        padding: 17px 13px;
        background: #fff;
        box-shadow: inset 1px -2px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px 0px 0px 0px;
        font-size: 22px;
        color: #282E35;
        line-height: 30px;
        &:last-child{
            border-radius: 5px 0 0 5px;
        }
        &--active{
            color: #fff;
            background: linear-gradient(180deg, #F65E2D 0%, #F69C1A 100%);
        }
        &-content{
            position: relative;
            width: calc( 100% - 189px );
            padding: 30px 150px 40px 50px;
            display: none;
            &--active{
                display: block;
            }
            h4{
                font-size: 28px;
                margin-bottom: 26px;
            }
            p{
                letter-spacing: -0.03em;
                position: relative;
                z-index: 1;
            }
        }
        &-text{
            width: 80%;
            padding-top: 3px;
            line-height: 30px;
        }
    }
    &__img{
        right: 0;
        bottom: 0;
    }
    &__step{
        color: #F7692B;
    }
}
@media(max-width: 1500px){
    .steps{
        .print{
            right: calc(18% - 178px);
            z-index: -1;
        }
        &__item{
            &-title{
                font-size: 17px;
                line-height: 25px;
            }
            &-content{

            }
        }
    }
}
@media (max-width: 1300px){
    .steps{
        &__item{
            &-content{
                p{
                    font-size: 15px;
                }
            }
        }
    }
}
@media (max-width: 1200px) {
    .steps {
        &__item {
            &-content {
                p {
                    font-size: 14px;
                }
            }
        }
    }
}
@media (max-width: 992px){
    .steps{
        .print{
            width: 88px;
            height: 88px;
            right: 0;
            top: -5px;
            z-index: 1;
        }
        &__title {
            padding: 0;
        }
        &__subtitle{
            position: relative;
            p{
                width: 75%;
            }
        }
        &__list {
            width: 100%;
        }
        &__item{
            margin-bottom: 20px;
            box-shadow: 0px 4px 20px rgba(210, 210, 210, 0.5);
            &:first-child{
                margin-bottom: 30px;
            }
            &--active{
                background: #fff;
                color: #212529;
                .steps__item-header{
                    &::after{
                        content: '\2212';
                    }
                }
            }
            &-header{
                padding: 22px;
                text-align: left;
                position: relative;
                &::after{
                    content: '\002B';
                    position: absolute;
                    right: 9px;
                    top: 12px;
                    display: block;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 30px;
                    height: 30px;
                    background-color: #f7692b;
                    color: #fff;
                    font-size: 35px;
                }
            }
            &-content{
                width: 100%;
                padding: 22px;
                p{
                    width: auto;
                }
            }
        }
    }
}
@media(max-width: 576px){
    .steps{
        &__inner{
            padding: 35px 0 29px;
        }
        &__content{
            &-mobile{
                padding: 0 5px;
            }
        }
        &__title{
            padding: 0px 15px;
            letter-spacing: -0.03em;
        }
        &__subtitle{
            text-align: left;
            padding: 0 5px;
            margin-top: -5px;
            line-height: 24px;
            margin-bottom: 20px;
        }
        &__item{
            padding: 8px 13px 13px;
            &-header{
                padding: 0 50px 0 14px;
            }
            &-content{
                text-align: left;
                padding: 2px 13px;
                p{
                    padding: 0;
                    line-height: 24px;
                    letter-spacing: 0;
                    &.steps__item-text {
                        line-height: 24px;
                        letter-spacing: 0.0em;
                    }
                }
            }
        }
    }
}