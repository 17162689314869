.compare{
    background-color: #445262;
    &__inner{
       color: #fff; 
       padding: 80px 0 64px;
    }
    &__title{
        padding: 0 70px;
        margin-bottom: 50px;
        span{
            display: inline;
            color: orange;
            font-weight: inherit;
        }
    }
    &__table{
        background-color: #3A4756;
        box-shadow: 0px 4px 100px #3A4756;
        border-radius: 5px;
        font-size: 18px;
        position: relative;
        overflow-x: auto;
    }
    table{
        width: 1290px;
        p{
            margin: 0;
        }
        td,th{
            width: 25%;
            text-align: center;
        }
        th{
            text-transform: uppercase;
            border-bottom: 2px solid #445262;
        }
        td,th{
            border-right: 2px solid #445262;
            padding: 26px 15px 15px;
            &:last-child{
                border-right: none;
            }
        }
        tr{
            &:first-child{
                td{
                    padding-top: 20px;
                    padding-bottom: 0;
                }
            }
            &:last-child{
                td{
                    padding-bottom: 60px;
                }
            }
        }
        th:first-child,
        td:first-child {
            text-align: left;
            padding-left: 47px;
        }
    }
    &__footer{
        padding-top: 42px;
        h4{
            font-size: 28px;
            line-height: 30px;
            margin-bottom: 22px;
        }
        p{
            font-size: 18px;
            line-height: 30px;
        }
    }
    &__form{
        margin-top: 34px;
        .form-input{
            flex-basis: 47.5%;
        }
        .btn-big{
            margin-top: 0;
        }
    }
}
@media(max-width: 1300px){
    .compare{
        &__form{
            margin-top: 0;
        }
        .form__inputs-row{
            display: flex;
            flex-direction: column;
        }
        .form-input{
            margin-bottom: 25px;
        }
        input{
            text-align: center;
            max-height: 56px;
        }
    }
}
@media (max-width: 1200px){
    .compare{
        table{
            td,
            th {
                max-width: 25%;
            }
        }
    }
}
@media (max-width: 992px) {
    .compare {
        &__title {
            padding: 0 30px;
        }
        table {
            td,
            th {
                max-width: 20%;
            }
        }
    }
}
@media (max-width: 576px) {
    .compare {
        &__inner{
            padding: 45px 0 59px;
        }
        table {
            td{
                padding-top: 15px;
            }
            th:first-child,
            td:first-child{
                padding-left: 30px;
            }
            td, th{
                font-size: 14px;
                line-height: 19px;
                width: 130px;
            }
            tr:first-child td {
                padding-top: 0;
            }
        tr {
            &:last-child {
                td {
                    padding-bottom: 30px;
                }
            }
        }
        }
        &__footer {
            padding-top: 42px;
            padding-left: 7px;
            h4 {
                font-size: 19px;
                margin-bottom: 10px;
            }

            p {
                font-size: 14px;
                line-height: 24px;
            }
        }
        input {
            text-align: left;
        }
    }
}