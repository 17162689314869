.media{
    &__inner{
        padding: 65px 0 87px;
    }
    &__title{
        margin-bottom: 58px;
    }
    .slick-arrow{
        z-index: 1;
        top: 44%;
        transform: translateY(-50%);
        width: 17px;
        height: 40px;
        &.slick-prev{
            left: -46px;
        }
        &.slick-next{
            right: -45px;
        }
    }
   &__photos{
       margin-bottom: 29px;
   }
   &__video{
       width: 100%;
       max-width: 410px;
       height: 262px;
   }
}
.cloud{
    bottom: 30px;
    right: 40px;
    z-index: -1;
}
@media (max-width: 992px){
    .media{
        &__video{
            max-width: 300px;
            max-height: 193px;
        }
    }
}
@media (max-width: 768px){
    .media{
        .slick-arrow{
            &.slick-prev{
                left: 0px;
            }
            &.slick-next{
                right: 0px;
            }
        }
    }
}
@media (max-width: 480px){
    .media{
        &__title{
            margin-bottom: 28px;
        }
        &__inner{
            padding: 40px 0 58px;
        }
        &__video{
            max-width: 300px;
            max-height: 193px;
        }
        .slick-arrow{
            top: 48%;
            &.slick-prev{
                background-size: 25px 25px;
                left: 5px;
            }
            &.slick-next{
                background-size: 25px 25px;
                right: 2px;
            }
        }
    }
}
@media (max-width: 400px){
    .media{
        &__title{
            margin-bottom: 31px;
        }
        &__photos{
            margin-bottom: 13px;
            img{
                width: 232px;
                height: 179px;
            }
        }
        &__video{
            max-width: 235px;
            max-height: 149px
        }
    }
}