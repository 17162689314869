.pile{
    min-height: 717px;
    &__inner{
        padding: 75px 0 88px;
    }
    &__descr{
        font-size: 17px;
        line-height: 30px;
        color: #4E5865;
        padding-right: 5px;
        margin-bottom: 35px;
    }
    &-slider{
        .slick-dots{
            margin-top: 35px;
        }
        .slick-arrow{
            bottom: -10px;
        }
        .slick-next{
            right: 15%;
        }
        .slick-prev{
            left: 15%;
        }
    }
    &-slide{
        overflow: hidden;
    }
    &__img{
        max-width: 100%;
        max-height: 350px;
        margin: auto;
    }
}
@media (max-width: 1300px){
    .pile{
        &__img{
            margin: 0 auto;
        }
    }
}
@media (max-width: 1200px){
    .pile{
        &-slider{
            .slick-arrow{
             transform: translateX(-50%);
            }
            .slick-prev{
                left: 38%;
            }
            .slick-next{
                right: 35%;
            }
        }
        &__descr{
            margin-top: 35px;
        }
    }
}
@media (max-width: 992px){
    .pile{
        &-slider {
            .slick-prev {
                left: 19%;
            }

            .slick-next {
                right: 17%;
            }
        }
    }
}
@media (max-width: 576px){
    .pile{
        &__inner{
            padding-top: 32px;
        }
        &__title{
            padding: 0 25px;
        }
        &__descr{
            font-size: 14px;
            line-height: 24px;
            margin-top: 20px;
            padding-left: 5px;
        }
        &-slider {
            .slick-dots{
                margin-top: 12px;
            }
            .slick-prev {
                left: 15px;
            }

            .slick-next {
                right: 0;
            }
        }
    }
}