.portfolio{
    &__inner{
        padding: 75px 0;
    }
    &__title{
        margin-bottom: 58px;
    }
    &__item{
        background: #FFFFFF;
        box-shadow: 0px 4px 20px rgba(210, 210, 210, 0.5);
    }
    &__footer {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    &__btn{
        margin-top: 57px;
        margin-bottom: 28px;
        padding-left: 47px;
        padding-right: 47px;
    }
    &-slider{
        .slick-arrow:not(.object__photos) {
            top: 24px;
            left: 0;
            width: 48px;
            height: 48px;
            border: 2px solid #F69A1B;
            &.slick-next {
                right: 20px;
                left:  auto;
                background: url(../icons/slider-arrow-next.svg) center center no-repeat;
                background-size: 42px 20px;
            }
            
            &.slick-prev {
                background: url(../icons/slider-arrow-prev.svg) center center no-repeat;
                background-size: 42px 20px;
                right: 80px;
                left: auto;
                z-index: 1;
            }
        }
    }
}
@media (max-width: 992px){
    .portfolio{
        &-slider{
            .slick-arrow:not(.object__photos) {
                top: auto;
                bottom: 10px;
                &.slick-next {
                    left: 110px;
                }
                
                &.slick-prev {
                    left: 45px;
                }
            }
        }
    }
}
@media (max-width: 576px){
    .portfolio{
        &__inner{
            padding: 45px 0;
        }
        &__title{
            margin-bottom: 28px;
        }
        &-slider{
            .slick-arrow:not(.object__photos) { 
                bottom: 20px;
                &.slick-next {
                    left: 84px;
                }
                
                &.slick-prev {
                    left: 23px;      
                }
            }
        }
        &__btn{
            margin-top: 44px;
            margin-bottom: 24px;
            width: 100%;
        }
    }
}

.object{
    &__header{
        display: flex;
        flex-wrap: nowrap;
    }
    &__photos{
        max-width: 410px;
        img{
            max-height: 350px;
            margin: 0 auto;
        }
        .slick-arrow {
            top: 50% !important;
            transform: translateY(-50%) !important;
            z-index: 1;
            border: none !important;

            &.slick-next {
                right: 0px !important;
                left: auto !important;
            }
            &.slick-next,
            &.slick-prev{
                background-size: auto !important;
            }
            &.slick-prev {
                left: 5px !important;
                right: auto !important;
                z-index: 1;
            }
        }
    }
    &__title{
        font-size: 28px;
        margin-bottom: 22px;
        padding-right: 120px;
        letter-spacing: 0.01em;
        color: #282E35;
    }
    &__info{
        width: 75%;
        padding-left: 29px;
        padding-top: 35px;
        padding-right: 20px;
        font-size: 17px;
        ol{
            padding-left: 22px;
        }
        li{
            line-height: 30px;
        }
    }
    &__price{
        font-size: 21px;
        letter-spacing: 0.005em;
    }
    &__review{
        background: #F6F6F6;
        border-radius: 0px 0px 5px 5px;
        padding: 30px 35px 30px 45px;
        &-title{
            font-size: 21px;
        }
        &-text{
            font-size: 14px;
            margin-top: 18px;
            line-height: 27px;
            color: #4E5865;
            p{
                margin-bottom: 0;
            }
        }
        &-author{
            margin-top: 10px;
            text-transform: uppercase;
        }
    }
    &-slide{
        overflow: hidden;
    }
}
@media (max-width: 1300px){
    .object{
        &__info{
            padding-top: 15px;
            font-size: 15px;
            ol{
                margin-bottom: 5px;
            }
        }
    }
}
@media (max-width: 992px){
    .object{
        &__header{
            flex-wrap: wrap;
            justify-content: center;
        }
        &__title{
            padding-right: 0;
        }
        &__info{
            width: 100%;
            ol{
                margin-bottom: 15px;
            }
        }
        &__price{
            margin-bottom: 20px;
        }
    }
}
@media (max-width: 576px){
    .object{
        &__title{
            font-size: 17px;
            margin-bottom: 13px;
        }
        &__info{
            font-size: 14px;
            padding: 30px 18px 30px 22px;
            li:first-child{
                margin-bottom: 21px;
            }
            li{
                line-height: 25px;
                margin-bottom: -4px;
                letter-spacing: 0;
            }
        }
        &__price{
            font-size: 17px;
            span{
                display: block;
            }
        }
        &__review{
            padding: 10px 13px 80px 24px;
            &-title{
                font-size: 17px;
            }
            &-text{
                margin-top: 12px;
                font-size: 14px;
                line-height: 24px;
            }
            &-author{
                margin-top: 18px;
            }
        }
    }
}
@media (max-width: 480px){
    .object{
        &__photos{            
            max-width: 280px;
            max-height: 246px;
            overflow: hidden;
            img{
                object-fit: scale-down;
                object-position: center center;
            }
        }
    }
}