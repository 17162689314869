.promo{
    background-color: #333e4a;
    position: relative;
    &__inner{
        padding-top: 94px;
        overflow: hidden;
        min-height: 832px;
    }
    &-desktop__bg{
        left: 0;
        right: 0;
        &--block{
            width: 50%;
            flex: 0 0 50%;
        }
    }
    &__img{
        img{
            object-fit: scale-down;
        }
    }
    &__content{
        padding: 107px 75px 62px 0;
        position: relative;
        z-index: 1;
        width: 50%;
        flex: 0 0 50%;
        color: #fff;
        font-size: 18px;
    }
    &__suptitle{
        font-size: 1.33em;
        margin-bottom: 0;
        margin-top: 6px;
    }
    &__title{
        font-weight: 800;
        font-size: 82px;
        line-height: 96px;
        margin-top: 27px;
    }
    &__price{
        display: inline-block;
        background-color: #f6f6f6;
        box-shadow: inset 1px -3px 4px rgba(176, 176, 176, 0.25);
        border-radius: 2px;
        padding: 24px 14px 24px 25px;
        font-size: 36px;
        line-height: 36px;
        color: #000;
        margin-top: 32px;
        letter-spacing: 0.06em;
        span{
            font-size: 48px;
        }
        .promo__price-custom{
            color: #F69A1B;
        }
    }
    &__links{
        margin-top: 35px;
    }
    &__link{
        font-size: 16px;
        letter-spacing: 0.09em;
        text-transform: uppercase;
        color: #fff;
        margin-right: 57px;
        margin-top: -5px;
        position: relative;
        &-arrow{
            display: inline-block;
            width: 21px;
            height: 18px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            margin-left: 12px;
        }
        &:hover{
            text-decoration: none;
            color: #F69A1B;
        }
    }
    &__btn{
        margin-top: 0;
        padding: 0 27px;
        letter-spacing: 0.07em;
        font-size: 16px;
    }
    &__text{
        margin-top: 107px;
    }

    &__info{
        position: absolute;
        background: rgba(255, 255, 255, 0.5);
        border-radius: 5px;
        color: #282E35;
        bottom: 63px;
        left: 20.4%;
        &-inner{
            position: relative;
            &::before{
                content: '';
                position: absolute;
                background-color: #fff;
                width: 58px;
                height: 56px;
                bottom: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 5px 0px 0px 5px;
                background: url('../img/promo/info.svg') center center/contain no-repeat,
                #fff;
                background-size: 33px;
                cursor: pointer;
            }
            &::after{
                content: url('../img/promo/info-arrow.png');
                position: absolute;
                top: -57px;
                left: 20px;
            }
        }
        &-text{
            font-size: 21px;
            text-align: center;
            padding: 13px 17px 13px 73px;
        }
    }
}

@media (max-width: 1500px){
    .promo{
        &__inner{
            min-height: 745px;
        }
        &__content{
            font-size: 15px;
            padding-top: 62px;
        }
        &__title{
            font-size: 64px;
            line-height: 78px;
        }
        &__price{
            margin-top: 20px;
            span{
                font-size: 35px;
            }
        }
        &__text{
            margin-top: 30px;
        }
        &__link{
            margin-right: 0;
            margin-top: 0;
            align-self: center;
        }
        &__info{
            bottom: 38px;
            left: 10.4%;
        }
    }
}
@media (max-width: 1200px){
    .promo{
        &__content{
            padding-right: 40px;
            font-size: 14px;
            padding-top: 40px;
        }
        &__title{
            font-size: 50px;
            line-height: 64px;
        }
        &__links{
            margin-top: 20px;
            flex-direction: column;
        }
        &__link{
            margin-top: 15px;
        }
    }
}
@media (max-width: 992px){
    .promo{
        &__content{
            width: 100%;
            flex-basis: 100%;
            padding: 30px 0 40px;
        }
        &__btn{
            padding: 27px;
        }
        &__title{
            margin-bottom: 0;
        }
        &__title,
        &__links{
            text-align: center;
        }
        &__text{
            margin-top: 40px;
        }
        &__link{
            margin-right: 0;
        }
        &__img{
            position: relative;
            width: 100%;
            img{
                object-fit: cover;
                width: 100%;
            }
        }
        &__inner{
            padding-top: 70px;
        }
        &__suptitle{
            margin-top: 0;
        }
    }
}
@media (max-width: 768px){
    .promo{
        &__inner{
            padding-top: 107px;
        }
        &__content{
            font-size: 11.5px;
            padding: 30px 0 34px;
        }
        &__title{
            font-size: 37px;
            line-height: 47px;
            letter-spacing: 0.03em;
            margin-top: 7px;
            margin-right: 10px;
            text-align: left;
        }
        &__suptitle{
            letter-spacing: 0.03em;
        }
        &__price{
            margin-top: 16px;
            padding: 10px 13px 10px 24px;
            font-size: 19px;
            line-height: 31px;
            letter-spacing: 0.03em;
            span{
                font-size: 27px;
            }
        }
        &__text{
            margin-top: 19px;
            letter-spacing: 0.02em;
        }
        &__btn{
            letter-spacing: 0;
        }
        &__link{
            font-size: 14px;
            align-self: flex-start;
            letter-spacing: .11em;
            margin-top: 18px;
            margin-left: 3px;
            &-arrow{
                margin-left: 10px;
            }
        }
        &__info {
            &-inner {
                min-height: 57px;
            }
            &-text {
                font-size: 19px;
            }
        }
    }
}
@media (max-width: 576px) {
    .promo{
        &__info{
            &-inner{
                &:after{
                    content: none;
                }
            }
        }
    }
}
@media (max-width: 480px){
    .promo{
        &__img{
            height: 325px;
            img{
                object-position: left;
                height: 100%;
            }
        }
        &__info {
            bottom: 29px;
            left: 20px;
            &-inner {
                min-height: 40px;
                &::before{
                    width: 40px;
                    height: 40px;
                    background-size: 24px;
                }
            }
            &-text{
                padding: 10px 8px 11px 50px;
                font-size: 13px;
            }
        }
    }
}