.btn{
    max-height: 72px;
    padding-left: 27px;
    padding-right: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    color: #fff;
    background: linear-gradient(180deg, #F65E2D 0%, #F69C1A 100%);
    box-shadow: inset 1px -2px 4px rgba(0, 0, 0, 0.25);
    font-size: 1em;
    font-weight: 600;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    cursor: pointer;
    &:hover{
        background: linear-gradient(180deg, #FF8E69 0%, #FFBB59 100%);
        color: #fff;
        text-decoration: none;
    }
    &-big{
        height: 72px;
        margin-top: 35px;
        line-height: calc(100% - 16px);
    }
    &:focus{
        outline: none;
        border: none;
    }
}

.callback{
    height: 48px;
    white-space: nowrap;
}
@media (max-width: 1300px){
    .btn{
        height: 38px;
        &-big{
            height: 72px;
        }
    }
}
@media (max-width: 768px){
    .callback {
        max-width: 135px;
        font-size: 10px;
    }
}
@media (max-width: 576px){
    .btn{
        &-big{
            height: 56px;
            letter-spacing: 0;
        }
    }
}