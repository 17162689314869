.company{
    &__inner{
        background-color: #F6F6F6;
        padding: 75px 0 60px;
    }
    &__title{
        margin-bottom: 31px;
    }
    &__descr{
        letter-spacing: -0.025em;
        line-height: 28px;
        margin-bottom: 36px;
    }
    &__info{
        position: relative;
        background-color: #fff;
        max-height: 280px;
        padding: 25px 20px;
        z-index: 1;
        margin-bottom: 36px;
    }
    .slick-arrow{
        z-index: 1;
        top: 40%;
        transform: translateY(-50%);
        width: 17px;
        height: 40px;
        &.slick-next{
            right: -10px;
        }
        &.slick-prev{
            left: 0;
        }
    }
    &-slide{
        overflow: hidden;
        img{
            max-height: 230px;
        }
    }
}
.bush{
    z-index: 0;
    left: -45%;
    top: -3%;
}
@media (max-width: 1300px){
    .bush{
        display: none;
    }
}
@media (max-width: 992px){
    .company{
        .slick-arrow{
            top: 50%;
        }
    }
}
@media (max-width: 576px){
    .company{
        &__inner{
            padding: 45px 0;
        }
        &__title{
            margin-bottom: 20px;
        }
        &__descr{
            padding: 0 5px;
            line-height: 24px;
            margin-bottom: 28px;
            p{
                margin-bottom: 10px;
            }
        }
        &__info{
            padding: 26px 15px 20px 25px;
            margin-bottom: 32px;
        }
        .slick-arrow{
            top: 40%;
            &.slick-prev{
                background-size: 25px 25px;
                left: 20px;
            }
            &.slick-next{
                background-size: 25px 25px;
                right: 16px;
            }
        }
        &__photo{
            width: 232px;
            max-height: 173px;
        }
    }
}