.why{
    background: #F6F6F6;
    &__inner{
        padding: 75px 0 0px;
    }
    &-span{
        display: block;
    }
    &__demo{
        
    }
    &__img{
        margin-top: -46px;
        margin-left: 118px;
    }
    &__label{
        position: absolute;
        &-inner{
            position: relative;
            &::before{
                content: url('../img/why/check.svg');
                width: 32px;
                height: 32px;
                background-color: #fff;
                position: absolute;
                left: -53px;
                top: -4px;
                display: flex;
                justify-content: center;
                align-items: center;
                line-height: calc(100% - 32px);
            }
        }

        &:first-child{
            left: 8.4%;
            bottom: 47.5%;
        }
        &:nth-child(2) {
            left: 39.8%;
            bottom: 55%;
        }
        &:nth-child(3) {
            bottom: 56.7%;
            right: -4.5%;
        }
        &:nth-child(4) {
            bottom: 34.6%;
            right: -3.1%;
        }
        &:last-child {
            bottom: 12%;
            left: 41.6%;
        }

        &:first-child &-inner,
        &:nth-child(2) &-inner{
            &::before{
                right: -53px;
                left: auto;
            }
        }
        &-active{
            .why__label-inner {
                &:before{
                    content: url('../img/why/plus.svg');
                    background-color: #F7692B;
                    left: -52px;
                    top: -7px;
                    animation-name: radial-pulse;
                    animation-duration: 1s;
                    animation-iteration-count: infinite;
                }
            }
        }
        &:last-child{
            margin-bottom: 15px;
        }
    }
}
@media (max-width: 1450px){
    .why{
        &__label{
            &:nth-child(3),
            &:nth-child(4) {
                right: 1.5%;
            }
        }
    }
}
@media (max-width: 1200px){
    .why{
        &__label{
            &-inner{
                font-weight: 800;
            }
        }
    }
}
@media (max-width: 992px){
    .why{
        &__container{
            display: flex;
            flex-direction: column;
        }
        &__inner{
            padding-bottom: 48px;
        }
        &__demo{
            display: flex;
            flex-direction: column;
        }
        &-span {
            display: inline;
        }
        &__descr{
            order: 2;
        }
        &__img{
            margin-top: -90px;
            margin-left: 0;
            align-self: flex-start;
        }
        &__labels{
            margin-top: -90px;
        }
        &__descr{
            order: 2;
        }
        &__label{
            position: relative;
            left: 0 !important;
            right: 0 !important;
            bottom: 0 !important;
            margin-bottom: 20px;
            &-inner{
                font-weight: normal;
                padding-left: 44px;
                &::before{
                    left: 0;
                    top: 0;
                }
            }
            &:first-child &-inner,
            &:nth-child(2) &-inner {
                &::before{
                    left: 0;
                    right: auto;
                }
            }
            &-active{
                .why__label-inner {
                    &:before {
                        left: 0;
                        top: 0;
                    }
                }
            }
        }
    }
}
@media (max-width: 576px){
    .why{
        &__inner{
            padding-top: 10px;
        }
        &__img{
            max-height: 255px;
            margin-top: -49px;
            margin-left: -55px;
        }
        &__labels{
            margin-top: -36px;
        }
        &__label{
            margin-bottom: 20px;
            font-size: 13px;
            &-inner{
                &::before{
                    width: 26px;
                    height: 26px;
                    left: 5px;
                }
            }
            &:first-child &-inner,
            &:nth-child(2) &-inner {
                &::before {
                    left: 5px;
                }
            }
            &-active {
                .why__label-inner {
                    &:before {
                        left: 5px;
                    }
                }
            }
        }
        &__descr{
            padding-left: 5px;
        }
    }
}

@keyframes radial-pulse {
    0% {
        box-shadow: 0 0 10px rgba(247, 105, 43, 0.8);
    }
    100% {
        box-shadow: 0 0 100px rgba(247, 105, 43, 1);
    }
}