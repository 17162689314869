.contacts{
    min-height: 600px;
    color: #282E35;
    text-align: center;
    &__title{
        font-weight: 800;
        font-size: 28px;
    }
    &__phone{
        font-weight: 600;
        font-size: 21px;
        color: #282E35;
    }
    &__mail,
    address{
        font-size: 21px;
        line-height: 29px;
        color: #282E35;
        margin: 0;
    }
    address{
        white-space: nowrap;
    }
    &__info{
        height: 400px;
        max-width: 410px;
        background-color: #fff;
        box-shadow: 0px 4px 20px rgba(210, 210, 210, 0.5);
        border-radius: 5px;
        padding: 28px 78px 40px;
        right: 16.4%;
        bottom: 96px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }
    &__btn{
        width: 188px;
        font-size: 13px;
        letter-spacing: 0.11em;
        margin-top: 20px;
    }
}
.map{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
@media(max-width: 992px){
    .contacts{
        &__title{
            font-size: 17px;
            line-height: 23px;
        }
        &__info{
            padding: 32px 28px 38px;
            max-height: 298px;
            max-width: 280px;
            bottom: 42px;
            left: 0;
            right: 0;
            margin: 0 auto;
        }
        &__phone{
            font-size: 14px;
            line-height: 25px;
        }
        &__mail,
        address{
            font-size: 14px;
            line-height: 19px;
        }
        &__btn{
            max-width: 222px;
            height: 56px;
        }
    }
}
@media (max-width: 576px){
    .contacts{
        min-height: 651px;
        &__btn{
            width: 100%;
        }
    }
}