.header{
    top: 0;
    z-index: 10;
    font-size: 13px;
    background-color: #E5E5E5;
    p{
        margin: 0;
    }
    &__inner{
        padding: 25px 0;
        max-height: 96px;
    }
    &__logo{
        margin-right: 25px;
    }
    &__text{
        font-size: 1.0769em;
        line-height: 1.8461;
        letter-spacing: 0.02em;
        max-width: 316px;
        margin: 0 10px 0 0;
    }
    .detector{
        margin-left: 35px;
    }
    &__menu{
        background-color: #333E4A;
        position: absolute;
        width: 100%;
        height: 100vh;
        top: 70px;
        transition: left 0.3s;
        left: -100%;
        &--active{
            left: 0;
        }
    }
}
@media (max-width: 1300px){
    .header{
        font-size: 11px;
        &__logo{
            margin-right: 15px;
        }
        &__text{
            max-width: 250px;
        }
        .detector{
            margin-left: 0;
        }
        .phone{
            font-size: 15px;
        }
    }
}
@media (max-width: 992px){
    .header{
        font-size: 13px;
        &__inner{
            padding: 15px 0;
            max-height: 70px;
        }
        .hamburger{
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
        }
        &__menu{
            padding: 43px 0;
        }
        &__btn{
            margin-right: 30px;
        }
    }
}
@media (max-width: 767px){
    .header{
        &__inner{
            max-height: 112px;
            flex-wrap: wrap;
            padding: 5px 0;
        }
        .logo{
           width: 208px;
           height: 44px; 
           margin: 0;
           flex-basis: auto;
        }
        .phone{
            font-size: 15px;
        }
        .hamburger{
            position: relative;
            right: auto;
            top: auto;
            transform:  translateY(0);
        }
        .phone,
        &__btn{
            flex-basis: 50%;
        }
        .line{
            margin: 5px 0 10px;
        }
        &__menu{
            top: 112px;
        }
        &__btn{
            margin-right: 0;
        }
    }
}
@media (max-width: 480px){
    .header{
        &__inner{
            padding: 6px 5px 11px;
        }
        .line{
            margin: 5px 0 7px;
        }
    }
}