@function max($numbers...) {
	@return m#{a}x(#{$numbers});
}

@function min($numbers...) {
	@return m#{i}n(#{$numbers});
}

*{
 box-sizing: border-box;
 font-family: 'Manrope', sans-serif;
 font-weight: normal;
}

h1,h2,h3,h4,h5,h6,p{
	margin: 0;
}

body{
	position: relative;
	margin: 0;
}

main{
	overflow: hidden;
}

input{
	border: none;
	outline: none;
	&:focus,
	&:focus-within,
	&:active,
	&:visited{
		outline: none;
		border: none;
	}
}
button{
	border: none;
	outline: none;
	&:focus,
	&:focus-within,
	&:active,
	&:visited{
		border: none;
		outline: none;
		&-visible{
			border: none;
			outline: none;
		}
	}
}

.logo{
	width: 230px;
	height: 49px;
	display: flex;
	align-items: center;
	img{
		width: 100%;
		object-fit: scale-down;
	}
	&--footer{
		margin-left: -87px;
	}
}
@media (max-width: 992px){
	.logo{
		&--footer{
			margin-left: 0;
			order: 1;
		}
	}
}

.detector {
	font-size: 1em;
	letter-spacing: 0.05em;
	position: relative;
	.detector__led{
		position: absolute;
		left: -24px;
		transform: translateY(-50%);
		top: 50%;
		width: 13px;
		height: 13px;
		border-radius: 50%;
		background-color: red;
		&--active{
			background-color: #44BD6D;
		}
	}
	&__text{
		text-transform: uppercase;
	}
}

a{
	&:hover{
		text-decoration: none;
	}
}

.phone{
	font-size: 21px;
	font-weight: 600;
	line-height: 29px;
	display: flex;
	align-items: center;
	a{
		color: #282E35;
	}
}

.hamburger{
	width: 25px;
	max-height: 21px;
	align-self: center;
	span{
		display: block;
		height: 3px;
		margin-bottom: 6px;
		background-color: #000;
		transition: .3s transform;
	}
	
	&--active{
		span{
			margin-bottom: 0;
			&:first-child{
				transform: rotate(45deg) translateY(3px);
			}
			&:nth-child(2){
				display: none;
			}
			&:last-child{
				transform: rotate(-45deg) translateY(-2px);
			}
		}
	}
}

.line{
	width: 100%;
	height: 1px;
	background-color: #f6f6f6;
	margin: 0 5px;
}

.menu {
	&__item{
		font-size: 15px;
		text-transform: uppercase;
		margin-bottom: 40px;
	}
}
.text{
	font-size: 18px;
}
.shrub{
	bottom: -78px;
}
.bird{
    top: -108px;
    right: -95px;
}
.orange{
	color: #F69A1B;
	font-weight: 800;
}
.leaf{
	position: absolute;
	left: -25px;
	top: 28px;
}
 .title {
 	font-weight: 800;
 	font-size: 50px;
 	line-height: 68px;
 	text-align: center;
 	color: #282E35;
 	margin-bottom: 46px;
 }
@media (max-width: 1450px){
	.shrub {
		bottom: -165px;
	}
}
@media (max-width: 1300px) {
	.logo {
		width: 180px;
		height: 40px;
	}
	.phone {
		font-size: 21px;
		a {
			color: #282E35;
		}
	}
}
@media (max-width: 992px){
	.menu {
		padding-left: 20px;
		list-style: none;
		&__item{
			margin-bottom: 28px;
			letter-spacing: 0.06em;
		}
		.menu__link{
			color: #fff;
		}
	}
	.shrub,
	.bird {
		display: none;
	}
	.leaf {
		position: relative;
		left: 0;
		top: 0;
	}
}
@media (max-width: 576px){
	.text {
		font-size: 15px;
	}
	.leaf{
		width: 112.65px;
		height: 114.64px;
	}
}

.descr {
	font-size: 17px;
	line-height: 30px;
	color: #4E5865;
}

.slick-arrow {
	position: absolute;
	font-size: 0;
	width: 16px;
	height: 32px;

	&.slick-prev {
		background: url('../icons/slider-arrow-prev.svg') center center/cover no-repeat;
	}

	&.slick-next {
		background: url('../icons/slider-arrow-next.svg') center center/cover no-repeat;
	}

	&:focus {
		outline: none;
	}
}

.slick-dots {
	display: flex;
	padding-left: 0;
	justify-content: center;
	bottom: 0;

	button {
		display: none;
	}

	li {
		width: 14px;
		height: 14px;
		margin: 0 8px;
		border: 2px solid #333E4A;
		border-radius: 100%;

		&::marker {
			font-size: 0;
		}
	}

	.slick-active {
		background-color: #333E4A;
	}
}

@media (max-width: 576px) {
	.descr {
		font-size: 14px;
		line-height: 24px;
		margin-top: 20px;
	}
}
/*end fancybox*/
@import 'fonts';
@import 'colors';
@import 'buttons';
@import "./bootstrap";
@import '../../node_modules/@fancyapps/fancybox/dist/jquery.fancybox';
@import "../../node_modules/slick-carousel/slick/slick.scss";
@import 'blocks/header';
@import 'blocks/promo';
@import 'blocks/pile';
@import 'blocks/why';
@import 'blocks/advantages';
@import 'blocks/comparison';
@import 'blocks/portfolio';
@import 'blocks/steps';
@import 'blocks/media';
@import 'blocks/company';
@import 'blocks/contacts';
@import 'blocks/footer';

@media (min-width: 1300px) {
	.container {
		max-width: 1320px;
	}
}

 .form {
 	&-popup {
		padding: 55px 49px 66px;
 		max-width: 443px;
 	}

 	&__title {
 		font-size: 36px;
 		margin-bottom: 15px;
 	}

 	&__subtitle {
 		font-size: 17px;
 		margin-bottom: 45px;
 	}

 	&__inputs {
		&-row{
			display: flex;
			justify-content: space-between;	
		}
 		&-col {
 			display: flex;
 			flex-direction: column;
 		}
 	}

 	&__input {
 		border-radius: 2px;

 		&-text {
 			border: 1px solid #EAEAEA;
 			box-shadow: inset 1px -3px 4px rgba(176, 176, 176, 0.25);
 			border-radius: 2px;
 			padding: 25px;
 			font-size: 18px;
 			letter-spacing: 0.3em;
 			color: #3A4756;
 			max-height: 70px;
 		}

 		&-btn {
 			padding: 27px 15px;
 			margin-top: 35px;
 			line-height: calc(100% - 16px);
			height: 72px;
 		}
		 &-wrapper{
			margin-bottom: 27px;
			display: flex;
			align-items: center;
		 }
		&-radio{
			display: none;
			& + &--circle{
				position: relative;
				width: 23px;
				height: 23px;
				margin-right: 13px;
				border: 1px solid #CACACA;
				border-radius: 100%;
				&:before{
					content: "";
					position: absolute;
					width: 11px;
					height: 11px;
					left: 0;
					right: 0;
					top: 0;
					bottom: 0;
					margin: auto;
					background-color: transparent;
					border-radius: 100%;
				}
			}
			&:checked + &--circle{
				&:before{
					background-color: #F69A1B;
				}
			}
		}
 	}
 }


 .with-shadow{
	box-shadow: 0px 4px 20px rgba(210, 210, 210, 0.5);
 }
 .b-radius-5{
	 border-radius: 5px;
 }
 .list{
	margin-left: 0;
	margin-right: 0;
	padding: 25px 8px 15px;
 }
.item {
	display: flex;
	flex-direction: column;
	align-items: center;
	h4{
		text-align: center;
		font-weight: 600;
		font-size: 19px;
	}
	&__img {
		width: 56px;
		height: 56px;
		margin-bottom: 8px;

		img {
			width: 100%;
		}
	}

	p {
		font-size: 14px;
		line-height: 19px;
		text-align: center;
		color: #4E5865;
	}
	&-adv{
		padding: 30px 0 22px;
		margin-top: 15px;
		.item__descr{
			margin-top: 13px;
		}
	}
	&-company{
		.item__descr{
			h4,p{
				text-align: left;
			}
			h4{
				margin-bottom: 19px;
				letter-spacing: -0.09em;
			}
			p{
				font-size: 18px;
				line-height: 28px;
				letter-spacing: -0.03em;
			}
		}
	}
}
@media (max-width: 1300px){
	.item{
		&-company{
			.item__descr{
				p{
					font-size: 14px;
					line-height: 24px;
				}
			}
		}
	}
}
 @media (max-width: 768px){
	.title {
		font-size: 24px;
		line-height: 32px;
	}
	.list {
		.item{
			margin-bottom: 15px;
		}
	}
 }
 @media (max-width: 576px){
	 .form{
		&-popup{
			padding: 50px 40px;
			max-width: 400px;
		}
	 }
	.list {
		padding: 25px 0px 0;
		.item {
			&__img {
				width: 56px;
				height: 56px;
				margin-bottom: 9px;
			}

			p {
				font-size: 13px;
				line-height: 18px;
			}
		}
	}
	.item {
		&-adv {
			padding: 20px 15px 0 25px;
			.item__img{
				margin-bottom: 10px;
			}
		}
	}
	.title {
		margin-bottom: 25px;
	}
	.item{
		&-company{
			height: auto;
			.item__descr{
				h4,p{
					font-size: 14px
				}
				h4{
					letter-spacing: -.02em;
					margin-bottom: 14px;
				}
				p{
					line-height: 22px;
					letter-spacing: 0em;
					margin-bottom: 13px;
				}
			}
		}
	}
 }
 @media (max-width: 480px){
	 .form{
		 &-popup{
			 max-width: 300px;
		 }
		 &__title{
			font-size: 25px;
		}
		&__subtitle{
			font-size: 15px;
			margin-bottom: 30px;
		}
		 &__input{
			 &-text{
				 font-size: 15px;
				 letter-spacing: initial;
				 max-height: 50px;
			 }
			 &-btn{
				font-size: 13px;
				max-height: 50px;
				margin-top: 20px;
			 }
		 }
	 }
 }
.bg-dark{
	background-color: #3a4757 !important;
}
 .quiz{
	 margin-top: 90px;
	 box-shadow: 0px 4px 20px rgba(34, 34, 34, 0.5);
	 border-radius: 5px;
	 overflow: initial;
	 &-start{
		 margin-top: 102px;
	 }
	 &-firstscreen{
		padding: 58px 49px 53px;
		height: 540px;
		max-width: 972px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		.form-title{
			padding: 0 120px;
		}
		.form__input-btn{
		padding: 27px 26px;
		margin: 57px auto 0;
		}
		.form__subtitle{
			margin-top: 18px;
		}
	}
	&-screen{
		display: none;
		position: relative;
		color: #282E35;
		max-width: 1178px;
		width: 1178px;
		max-height: 578px;
		height: 578px;
		padding: 43px 69px 66px;
		flex-direction: column;
		&--active{
			display: flex;
		}
		.form__title{
			font-size: 30px !important;
			margin-bottom: 40px !important;
			letter-spacing: 0.04em;
		}
		&__left{
			width: 54.72%
		}
		&__right{
			padding: 35px 40px;
			button.form-input{
				text-transform: initial;	
				max-width: 343px;
				margin-top: 20px;
				padding: 16px 8px;
				position: relative;
				max-height: 50px;
				&:before,
				&:after{
					content: '';
					position: absolute;
				}
				&:before{
					left: 0;
				}
				&:after{
					right: 0;
				}
			}
			img{
				max-width: 100%;
				margin: auto;
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
			}
		}
		&__bg{
			right: 0;
			top: 0;
			bottom: 0;
			left: 56.2%;
		}
	}
	&-info{
		position: absolute;
		bottom: 37px;
		left: 32px;
		right: 33px;
		font-size: 16px;
		line-height: 30px;
	}
	 .form__title{
		padding: 0;
		font-weight: 800;
		font-size: 40px;
		margin-bottom: 20px;
	 }
	 .form__subtitle{
		padding: 0 15px;
		line-height: 28px;
		margin-bottom: 0;
	 }
	 .text-under-btn{
		font-size: 14px;
		margin-top: 21px;	
	 }
	 .form__input-btn{
		 max-width: 280px;
		 margin-top: 17px;
	 }
	 label{
		margin-bottom: 0;
		letter-spacing: 0.03em;
	 }
	 .quiz &__btn{
		&-info{
			transform: skewX(-12deg);
			line-height: normal;
			max-width: 340px;
			font-size: 13px;
			padding: 17px 14px;
			height: auto;
		}
	}
 }
 @media (max-width: 1200px){
	.quiz{
		&-screen{
			padding: 43px 30px 66px;
			max-width: 960px;
		}
	}
 }
 @media (max-width: 992px){
	 .quiz{
		&-screen{
			max-width: 768px;
		}
		.form{
			&__title{
				margin-bottom: 20px !important;
			}
		}
	 }
 }
 @media (max-width: 768px){
	 .quiz{
		&-screen{
			padding: 30px;
			max-width: 540px;
			text-align: center;
			&__left{
				width: 100%;
				.form__input-wrapper{
					&:last-child{
						margin-bottom: 0;
					}
				}
			}
			&__bg{
				top: auto;
				bottom: 0;
				left: 0;
				right: 0;
				.form__input-btn{
					margin-top: 0px;
				}
			}
			&__right{
				padding: 22px 40px;
				img{
					display: none;
				}
			}
		}
		.form{
			&__title{
				font-size: 25px !important;
				letter-spacing: .03em;
			}
		}
		&-info{
			display: none;
		}
	}
}
@media (max-width: 576px){
	.quiz{
		&-firstscreen{
			min-height: 540px;
			padding: 58px 16px 53px
		}
		&-screen{
			max-width: 100%;
		}
	}
}
@media (max-width: 480px){
	.quiz{
		&-firstscreen{
			min-height: 540px;
		}
		&-screen{
			max-width: 100%;
		}
		.form__title{
			font-size: 20px !important;
			line-height: 22px;
		}
		.form__input-wrapper{
			font-size: 15px;
			margin-bottom: 10px;
		}
		label{
			text-align: left;
		}
	}
}

.fancybox-button svg path {
    fill: #C4C4C4;
}