.adv{
    &__inner{
        padding: 60px 0 88px;
    }
    &__title{
        span{
            display: block;
            font-weight: inherit;
            letter-spacing: 0.04em;
        }
    }
    &__item{
        min-width: 190px;
        margin: 0 15px;
    }
}
@media (max-width: 1300px){
    .adv{
        &__content {
            overflow-x: auto;
        }
    }
}
@media (max-width: 576px){
    .adv{
        &__inner{
            padding: 38px 0 59px;
        }
        &__item{
            min-width: 200px;
            margin: 0 10px;
        }
    }
}