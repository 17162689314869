.footer{
    &__inner{
        background-color: #333E4A;
        color: #fff;
        padding: 22px 0 20px;
    }
    &__top{
        justify-content: space-between;
        align-items: center;
        padding-bottom: 25px;
    }
    &__line{
        left: 0;
        right: 0;
        width: 100%;
        margin: 0;
    }
    .policy{
        margin-bottom: 5px;
        text-decoration: underline;
        &:hover{
            text-decoration: none;
        }
    }
    &__bottom{
        padding: 15px 0 0;
        font-size: 14px;
    }
}
@media (max-width: 992px){
    .footer{
        &__top{
            flex-direction: column;
            padding-bottom: 20px;
        }
        .copyright{
            order: 2;
        }
        .policy{
            order: 3;
        }
        .copyright, .policy{
            margin-top: 15px;
        }
    }
}
@media (max-width: 576px){
    .footer{
        &__inner{
            padding: 22px 0 12px;
        }
        &__top{
            font-size: 13px;
        }
        &__bottom{
            font-size: 11px;
            padding: 6px 0 0;
        }
    }
}